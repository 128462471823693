<template>
  <div class="student-details-page">
    <v-card v-if="student && !student.registration_approved" color="yellow lighten-4">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-wrap body-2"
            >Registration approval
            <strong>Pending</strong></v-list-item-title
          >
        </v-list-item-content>

        <v-list-item-icon
          ><v-btn @click="showRegistrationApprovalDialog = true" small color="primary"
            ><v-icon class="mr-2" small>mdi-account-check</v-icon> Approve</v-btn
          ></v-list-item-icon
        >

        <v-list-item-icon
          ><v-btn @click="showRegistrationRejectionDialog = true" small color="red"
            ><v-icon class="mr-2" small>mdi-account-cancel</v-icon> Reject</v-btn
          ></v-list-item-icon
        >
      </v-list-item>
    </v-card>

    <v-card v-else-if="student && student.disabled" color="yellow lighten-4">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-wrap body-2"
            >The student account has been
            <strong>'Deactivated'</strong></v-list-item-title
          >
        </v-list-item-content>

        <v-list-item-icon
          ><v-btn @click="showActivateDialog = true" small color="primary"
            ><v-icon class="mr-2">mdi-account-check</v-icon> Activate</v-btn
          ></v-list-item-icon
        >
      </v-list-item>
    </v-card>
    <v-row justify="center">
      <v-col md="6">
        <div class="mt-6">
          <div class="text-center">
            <v-avatar v-if="student" size="120px">
              <v-img :src="student.image"></v-img>
            </v-avatar>
          </div>
          <v-divider class="mb-4 mt-6"></v-divider>
          <v-card :elevation="$vuetify.breakpoint.mdAndDown ? '0' : null">
            <v-list-item
              class="non-focused"
              v-for="(item, index) in profileItems"
              :key="index"
              :href="item.href || null"
              :to="item.to || null"
              @click="() => (item.action ? item.action() : {})"
            >
              <v-list-item-avatar class="align-self-center">
                <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                <v-avatar v-else-if="item.image">
                  <v-img :src="item.image"></v-img>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>{{ item.heading }}</v-list-item-subtitle>
                <v-list-item-title :class="item.valueClasses">{{
                  item.value
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon class="align-self-center" v-if="item.appendIcon"
                ><v-icon>{{ item.appendIcon }}</v-icon></v-list-item-icon
              >
            </v-list-item>
          </v-card>
        </div>  

        <!-- <div>
          <v-expansion-panels popout>
            <v-expansion-panel
              hide-actions
            >
            <v-expansion-panel-header>
              <v-row
              align="center"
              class="spacer"
              no-gutters
            >
            <v-col
                cols="4"
                sm="2"
                md="1"
              >
              <v-avatar
                v-if="student && student.parent_profile.father_image"
                alt="Father Image"
              >
                <v-img :src="student.parent_profile.father_image"></v-img>
              </v-avatar>
            </v-col>
          </v-row>
            </v-expansion-panel-header>
            </v-expansion-panel>
          </v-expansion-panels>
        </div> -->

        <v-divider class="mt-6"></v-divider>

        <!-- Extra subjects management -->
        <v-card class="mt-4 pb-3">
        <div class="subjects mt-4 mx-2">
          <v-list-item>
            <v-list-item-content>
              <h3 class="my-1">Extra Subjects</h3>
            </v-list-item-content>
            <v-list-item-icon>
            <v-btn small class="primary" @click="extraSubjectSheet.editId = student.id;extraSubjectSheet.visible=true;">
              Manage
            </v-btn>
            </v-list-item-icon>
          </v-list-item>

          <div v-if="student.extra_subjects && student.extra_subjects.length" class="mx-6 mt-1">
            <v-list-item
              class="px-0 my-n2"
              v-for="subject in student.extra_subjects"
              :key="subject"
            >
              <v-list-item-content>
                <v-list-item-title class="body-1">{{ subject.name }}</v-list-item-title>
              </v-list-item-content>
            <!-- <v-divider></v-divider> -->
            </v-list-item>
          </div>   
          <div class="text-center subtitle-2 mb-3" v-else> No Extra Subjects </div>           
        </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Extra Subject create edit sheet  -->
    <intract-create-edit-sheet
      persistent
      key="extra-subject-add-sheet"
      class="extra-subject-add-sheet"
      title="Add Extra Subject"
      :visible="extraSubjectSheet.visible"
      @close="extraSubjectSheet.visible = false;extraSubjectSheet.editId=null;"
      :fields="extraSubjectFormField"
      partial-update
      :edit-id="extraSubjectSheet.editId"
      :endpoint="endpoints.studentViewSet"
      :data-object="extraSubjectSheet.obj"
      create-success-message="Extra Subjects saved successfully!"
      edit-success-message="Extra Subjects saved successfully!"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (extraSubjectSheet.obj = obj)"
    >
    </intract-create-edit-sheet>


    <intract-create-edit-sheet
      persistent
      key="student-edit-sheet"
      class="student-edit-sheet"
      title="Edit Student"
      :visible="editStudentSheet.visible"
      @close="
        editStudentSheet.visible = false;
        editStudentSheet.editId = null;
        editStudentSheet.obj.image = null;
      "
      :fields="studentCreationFormFields"
      submit-button-text="Edit"
      :endpoint="endpoints.studentViewSet"
      :edit-id="editStudentSheet.editId"
      :data-object="editStudentSheet.obj"
      :create-success-message="editStudentSheet.editSuccessMessage"
      @objectCreated="onRefresh"
      @updateObject="(obj) => (editStudentSheet.obj = obj)"
    >
      <template v-slot:custom-field--image="{ obj }">
        <v-col class="text-center">
          <div>
            <v-avatar size="100px" class="mb-2">
              <v-img
                v-if="obj.image && obj.image.document"
                :src="obj.image.document"
              ></v-img>
              <v-img v-else :src="obj.image"></v-img
            ></v-avatar>
          </div>
          <v-btn
            class="my-2"
            color="primary"
            @click="$refs.profileImageField.$refs.input.click()"
            ><v-icon class="mr-3">mdi-image</v-icon
            ><span v-if="!obj.image">Add Image</span
            ><span v-else>Edit Image</span></v-btn
          >
          <v-file-input
            v-show="false"
            class="ma-0 pa-0"
            ref="profileImageField"
            @change="(file) => addProfileImage(file, obj)"
            accept="image/*"
          ></v-file-input>
        </v-col>
      </template>
      <template v-slot:custom-field--username="{ obj }">
        <v-text-field
          v-model="obj.username"
          outlined
          :error-messages="usernameError"
          maxlength="30"
          hide-details="auto"
          :rules="usernameRules"
          @input="checkUsernameExists(obj.username)"
        >
          <template #label>
            Username<span class="red--text"><strong> * </strong></span>
          </template>
        </v-text-field>
        <v-col cols="12" v-if="obj.phone || obj.full_name">
          Suggestions:
          <v-chip
            class="mx-1"
            v-if="obj.phone && obj.username != obj.phone"
            @click="
              obj.username = obj.phone;
              checkUsernameExists(obj.username);
            "
            >{{ obj.phone }}</v-chip
          >
          <v-chip
            class="mx-1"
            v-if="
              obj.full_name && obj.username != convertToUsername(obj.full_name)
            "
            @click="
              obj.username = convertToUsername(obj.full_name);
              checkUsernameExists(obj.username);
            "
            >{{ convertToUsername(obj.full_name) }}</v-chip
          >
        </v-col>
      </template>
    </intract-create-edit-sheet>

    <image-cropper-dialog
      ref="cropperDialog"
      :uploadedImage="uploadedImage"
      @onCrop="(croppedImage) => {
        updateObj.image = {
          document: croppedImage,
          file_name: imageName,
        };
      }"
    />

    <!-- delete student dialog -->
    <confirmation-dialog
      :visible="showDeleteDialog"
      title="Are you sure you want to delete this student?"
      description="This student will no longer show up anywhere inside the app."
      @successCallback="deleteStudent"
      @failureCallback="showDeleteDialog = false"
      delete-dialog
    />
    <!-- deactivate student dialog -->
    <confirmation-dialog
      :visible="showDisableDialog"
      title="Are you sure you want to deactivate this student's account?"
      description="This will deactivate the their account and will block them from logging in unless enabled again by the admins."
      @successCallback="disableStudent"
      @failureCallback="showDisableDialog = false"
      delete-dialog
    />
    <!-- active student dialog -->
    <confirmation-dialog
      :visible="showActivateDialog"
      title="Are you sure you want to activate this student's account?"
      description="This will allow them to login inside the app again."
      @successCallback="activateStudent"
      @failureCallback="showActivateDialog = false"
      delete-dialog
    />
    <!-- approve student dialog -->
    <confirmation-dialog
      :visible="showRegistrationApprovalDialog"
      title="Are you sure you want to approve this student registration?"
      description="This will allow them to login inside the app."
      @successCallback="() => approveStudentRegistration(true)"
      @failureCallback="showRegistrationApprovalDialog = false"
      delete-dialog
    />

    <!-- approve student dialog -->
    <confirmation-dialog
      :visible="showRegistrationRejectionDialog"
      title="Are you sure you want to reject this student registration?"
      @successCallback="() => approveStudentRegistration(false)"
      @failureCallback="showRegistrationRejectionDialog = false"
      delete-dialog
    />

    <!-- reset password dialog -->
    <confirmation-dialog
      :visible="showResetPasswordDialog"
      title="Are you sure you want to reset this student's password?"
      :description="`Resetting will set their password as their phone number. The new password will be <b>${
        student ? student.phone : ''
      }</b>.`"
      @successCallback="resetPassword"
      @failureCallback="showResetPasswordDialog = false"
    />
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import moment from "moment";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import ImageCropperDialog from "@components/dialogs/ImageCropperDialog"
import debounce from "debounce";
import { mapGetters, mapActions } from "vuex";
import EventBus from "@utils/event_bus";
// import { Share } from "@capacitor/share";
import Helper from "@utils/misc";

export default {
  name: "StudentDetails",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    IntractCreateEditSheet,
    ImageCropperDialog,
  },
  data() {
    return {
      usernameError: null,
      usernameRules: [
        (v) => !!v || v === 0 || "Required",
        (v) => /^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+$/.test(v) || "Invalid",
        (v) => !v || v.length >= 5 || "Should be minimum 5 characters",
      ],
      student: null,
      showDeleteDialog: false,
      showDisableDialog: false,
      showActivateDialog: false,
      showRegistrationApprovalDialog: false,
      showRegistrationRejectionDialog: false,
      showResetPasswordDialog: false,
      editStudentSheet: {
        visible: false,
        editSuccessMessage: "Student edited successfully!",
        editId: null,
        obj: {
          is_student: true,
          is_faculty: false,
          is_admin: false,
          institution: null,
        },
      },
      platform: null,
      extraSubjectSheet: {
        visible: false, 
        editId: null,
        obj: {
          institution: null,
        }
      },
      uploadedImage: null,
      updateObj: null,
      imageName: null,
    };
  },
  computed: {
    ...mapGetters(["allRooms", "allBuses", "currentInstitution"]),
    profileItems() {
      if (!this.student) return [];
      return [
        {
          heading: "Full Name",
          value: this.student.full_name,
          icon: "mdi-rename-box",
        },
        {
          heading: "Username",
          value: this.student.username,
          icon: "mdi-account",
          valueClasses: "student-details-username",
          action: async () => {
            await this.Helper.copyText(this.student.username);
            this.showSnackbar({
              title: "Username copied to clipboard!",
              type: "success",
            });
          },
          appendIcon: "mdi-content-copy",
        },
        {
          heading: "Phone",
          value: this.student.phone,
          icon: "mdi-phone",
          href: "tel:" + this.student.phone,
          appendIcon: "mdi-arrow-right",
        },
        ...this.student.fathers_phone ? [{
          heading: "Father's Phone",
          value: this.student.fathers_phone,
          icon: "mdi-phone",
          href: "tel:" + this.student.fathers_phone,
          appendIcon: "mdi-arrow-right",
        }] : [],
        {
          heading: "Classroom",
          value: this.student.room.sections,
          icon: "mdi-school",
          // to: {
          //   name: "ClassroomDetails",
          //   params: { roomId: this.student.room.id },
          // },
          // appendIcon: "mdi-arrow-right",
        },
        ...this.student.custom_institution_name ? [{
          heading: "Institution",
          value: this.student.custom_institution_name,
          icon: "mdi-bank",
        }]:[],
        ...this.student.address ? [{
          heading: "Residential Address",
          value: this.student.address,
          icon: "mdi-map-marker",
        }] : [],
        ...this.student.dob ? [{
          heading: "Date of Birth",
          value: moment(this.student.dob).format('Do MMMM YYYY'),
          icon: "mdi-cake",
        }] : [],
        ...this.student.blood_group ? [{
          heading: "Blood Group",
          value: this.student.blood_group,
          icon: "mdi-blood-bag",
        }]: [],
        ...this.student.parent_profile.father_name ? [{
          heading: "Father's Name",
          value: this.student.parent_profile.father_name,
          image: this.student.parent_profile.father_image,
        }]: [],
        ...this.student.parent_profile.mother_name ? [{
          heading: "Mother's Name",
          value: this.student.parent_profile.mother_name,
          image: this.student.parent_profile.mother_image,
        }]: [],
      ];
    },
    studentCreationFormFields() {
      return {
        image: {
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          // pattern: /^(?=.{8,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/,
        },
        full_name: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Full Name",
          required: true,
          md: 6,
          max: 255,
        },
        room: {
          fieldType: CreateEditFieldTypes.SELECT,
          label: "Classroom",
          md: 6,
          required: true,
          returnObject: true,
          // endpoint: th is.endpoints.classroomViewSet,
          disabled: this.currentInstitution.erp,
          helper: this.currentInstitution.erp
            ? "You can only edit classroom in ERP."
            : null,
          items: this.allRooms,
          itemText: "sections",
        },
        phone: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Phone",
          inputmode: "numeric",
          required: true,
          md: 6,
          max: 12,
          input: "phone",
          customRules: [
            (v) => v == null || v.length >= 10 || "Should be atleast 10 digits",
          ],
          pattern: /^(0|[1-9][0-9]*)$/,
        },
        username: {
          md: 6,
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
        },
        bus:{
          fieldType: CreateEditFieldTypes.SELECT,
          label: "Bus",
          md: 6,
          items: this.allBuses,
          itemValue: "id",
          itemText: "name",
          hide: !JSON.parse(this.currentInstitution.features).includes(7) 
        },
      };
    },
    eligibleExtraSubjectsEndpoint() {
      return this.Helper.addUrlParams(this.endpoints.subjectViewSet, ["room__isnull=true" ,"eligible_rooms__id=" + this.student.room.id]);
    },

    extraSubjectFormField() {
      return {
        extra_subjects: {
          fieldType: CreateEditFieldTypes.SELECT,
          label: "Extra Subjects",
          required: false,
          md: 12,
          // returnObject: true,
          endpoint: this.eligibleExtraSubjectsEndpoint,
          multiple: true,
          itemValue: "id",
          itemText: "name",
          items: [],
        },
      };
    },
  },
  methods: {
    ...mapActions(["setAppBarOptions", "resetAppBarOptions"]),

    convertToUsername(name) {
      return name.replace(/ /g, ".").trim().toLowerCase();
    },

    checkUsernameExists: debounce(async function (username) {
      if (!username) {
        this.usernameError = null;
        return;
      }
      var url = this.endpoints.checkUsernameExists + `?username=${username}`;
      if (await this.api.call(this.essentials, url)) {
        this.usernameError = "Username Exists";
      } else {
        this.usernameError = null;
      }
    }, 500),

    async addProfileImage(file, obj) {
      if (!file) return;
      this.uploadedImage = await this.Helper.toBase64(file);
      this.imageName = file.name; this.updateObj = obj;
      this.$refs.cropperDialog.initCropper(file.type);
    },

    async getStudentDetails() {
      var url =
        this.endpoints.studentViewSet + this.$route.params.studentId + "/";
      this.student = await this.api.call(this.essentials, url);
      EventBus.$emit("student_details__student_updated", this.student);
    },

    async resetPassword() {
      var url = `${this.endpoints.studentViewSet}${this.$route.params.studentId}/auth/password-reset/`;
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.POST
      );
      if (response) {
        this.showResetPasswordDialog = false;
        this.showSnackbar({
          title: "Password successfully reset!",
          text: `The new password of this student is <b>${this.student.phone}</b>`,
          type: "success",
        });
      }
    },

    async disableStudent() {
      this.showDisableDialog = false;
      var url = this.endpoints.studentViewSet + this.student.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { disabled: true }
      );
      if (response) {
        this.student.disabled = true;

        EventBus.$emit("student_details__student_deleted", this.student.id);
        this.showSnackbar({
          title: "Student has been deactivated!",
          text: "The student will no longer be able to login inside the app.",
          type: "success",
        });
      }
    },

    async approveStudentRegistration(value) {
      this.showRegistrationApprovalDialog = false;
      this.showRegistrationRejectionDialog = false;
      var url = this.endpoints.approveStudent + this.student.id + "/";
      // url = this.Helper.addUrlParams(url, `approve=${value}`)
      await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { approve: `${value}` }
      );

      if (value) {
        this.student.registration_approved = true;
        this.student.disabled = false;
        EventBus.$emit("student_details__student_added", this.student);
        this.showSnackbar({
          title: "Student Registration has been approved!",
          text: "The student will now be able to login into the app.",
          type: "success",
        });
      }
      else{
        EventBus.$emit("student_details__student_deleted", this.student);
        this.showSnackbar({
          title: "Student Registration has been rejected!",
          type: "info",
        });
        this.$router.back();
      }
    },

    async activateStudent() {
      this.showActivateDialog = false;
      var url = this.endpoints.studentViewSet + this.student.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { disabled: false }
      );
      if (response) {
        this.student.disabled = false;
        EventBus.$emit("student_details__student_added", this.student);
        this.showSnackbar({
          title: "Student has been activated!",
          text: "The student can now continue using the app as usual.",
          type: "success",
        });
      }
    },

    async deleteStudent() {
      this.showDeleteDialog = false;
      var url =
        this.endpoints.softDeleteCurrentStudentRecords + this.student.id + "/";
      url = Helper.addUrlParams(url,['disable_username=true'])
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        EventBus.$emit("student_details__student_deleted", this.student.id);
        this.showSnackbar({
          title: "Student successfully deleted!",
          text: "All records of the students have been removed",
          type: "success",
        });
        this.$router.back();
      }
    },

    async setAppBar() {
      this.setAppBarOptions({
        title: this.appBarTitle,
        actions: [
          {
            id: 1,
            title: "Edit Student",
            icon: "mdi-pencil",
            onClick: () => {
              this.editStudentSheet.visible = true;
              this.editStudentSheet.editId = Number(
                this.$route.params.studentId
              );
            },
          },
          {
            id: 5,
            title: "Share Details",
            icon: "mdi-share",
            onClick: async () => {
              // if (this.platform == "web") {
                let text = `Hey ${this.student.full_name}, download the ${this.endpoints.appTitle} app for our institution.\n\nInstitution Code: ${this.currentInstitution.code}\nLogin Username: ${this.student.username}\n\nDownload Links:\n\nAndroid: ${this.endpoints.playStoreLink}\n\niOS: ${this.endpoints.appStoreLink}\n\nWeb: ${this.endpoints.webAppLink}`;
                await this.Helper.copyText(text);
                this.showSnackbar({
                  title: "Invitation link copied to clipboard!",
                  type: "success",
                });
              // } else {
              //   Share.share({
              //     title: `Check out the ${this.endpoints.appTitle} app`,
              //     text: `Hey ${this.student.full_name}, download the ${this.endpoints.appTitle} app for our institution.\n\nInstitution Code: ${this.currentInstitution.code}\nLogin Username: ${this.student.username}\n\nDownload Links:\n\nAndroid: ${this.endpoints.playStoreLink}\n\niOS: ${this.endpoints.appStoreLink}\n\nWeb: ${this.endpoints.webAppLink}`,
              //   });
              // }
            },
          },
          {
            id: 2,
            title: "Reset Password",
            icon: "mdi-lock-reset",
            onClick: () => (this.showResetPasswordDialog = true),
          },
          ...(this.student && !this.student.disabled
            ? [
                {
                  id: 3,
                  title: "Deactivate Student",
                  icon: "mdi-account-remove",
                  onClick: () => (this.showDisableDialog = true),
                },
              ]
            : []),
          {
            id: 4,
            title: "Delete Student",
            icon: "mdi-delete",
            onClick: () => (this.showDeleteDialog = true),
          },
        ],
      });
    },

    async onRefresh() {
      this.getStudentDetails();
    },
  },

  beforeDestroy() {
    this.resetAppBarOptions();
  },

  async created() {
    await this.getStudentDetails();
    this.editStudentSheet.obj.institution = this.currentInstitution.id;
    this.extraSubjectSheet.obj.institution = this.currentInstitution.id;
    this.platform = await this.Helper.getDevicePlatform();
    this.setAppBar();
  },
};
</script>
<style scoped>
.v-application a {
  color: inherit !important;
}
</style>